/* eslint-disable */
import type { DocumentContext } from "next/document";
import Router from "next/router";

export function redirect(ctx: DocumentContext, redirect: string) {
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: redirect });
    ctx.res.end();
  } else {
    Router.push(redirect, redirect, { shallow: true });
  }
  return {};
}

export default redirect;
