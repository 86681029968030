import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import { getDataFromTree } from "@apollo/client/react/ssr";

import { graphqlEndpoint } from "~/constants";
import { useAuth } from "~/providers/auth";
import cookies from "next-cookies";
import App from "next/app";

const ssrMode = typeof window === "undefined";

const httpsLink = new HttpLink({
  uri: `https://${graphqlEndpoint}`,
  credentials: "include",
});

const splitLink = ssrMode
  ? httpsLink
  : split(
      ({ query }) => {
        let definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      new GraphQLWsLink(createClient({ url: `wss://${graphqlEndpoint}` })),
      httpsLink,
    );

const inMemoryCacheOptions = {
  typePolicies: {
    /**
     * Explicitly specify primary keys because Apollo normally expects `id` as the primary key for cache normalization.
     * See: https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-by-type
     */
    users: {
      keyFields: ["user_id"],
    },
    posts: {
      keyFields: ["post_id"],
    },
    lists: {
      keyFields: ["list_id"],
    },
    likes: {
      keyFields: ["post_id", "user_id"],
    },
    list_posts: {
      keyFields: ["post_id", "list_id"],
    },
    followers: {
      keyFields: ["user_id", "follower_id"],
    },

    /**
     * Fix mismatch between default Apollo and Hasura root types to allow for root fragments.
     * Root fragments can only match if the cache knows the __typename of the root objects, otherwise resulting
     * in a correct network response by Hasura but incorrect object parse by Apollo.
     * See: https://www.apollographql.com/docs/react/caching/cache-configuration/#overriding-root-operation-types-uncommon
     */
    query_root: {
      queryType: true,
    },
    mutation_root: {
      mutationType: true,
    },
    subscription_root: {
      subscriptionType: true,
    },
  },
};

// On the client, cache the Apollo client between page transitions.
export let apolloClient: ApolloClient<any> | undefined;

// @ts-ignore
const cache = new InMemoryCache(inMemoryCacheOptions);

/**
 * Initialize client with auth headers and optionally hot cache from server.
 * @param ctx
 */
export default function createApolloClient(ctx) {
  const authLink = new ApolloLink((operation, forward) => {
    if (ssrMode && ctx.auth.accessToken) {
      operation.setContext({
        headers: { Authorization: `Bearer ${ctx.auth.accessToken}` },
      });
    }
    return forward(operation);
  });

  cache.restore(ctx.apollo || {});

  return new ApolloClient({
    ssrMode,
    link: concat(authLink, splitLink),
    cache,
  });
}

/**
 *
 * Source: https://stackoverflow.com/questions/67022296/next-with-apollo-ssr-does-not-work-request-is-done-on-client
 */
export function withApollo(Component) {
  function GraphQLProvider({ client, apollo, ...props }) {
    const [auth] = useAuth();
    apolloClient =
      client || apolloClient || createApolloClient({ apollo, auth });

    return (
      <ApolloProvider client={apolloClient}>
        <Component client={apolloClient} {...props} />
      </ApolloProvider>
    );
  }

  const displayName = Component.displayName || Component.name || "Component";
  GraphQLProvider.displayName = `GraphQLProvider(${displayName})`;

  if (Component.getInitialProps) {
    GraphQLProvider.getInitialProps = async (ctx) => {
      const inAppContext = Boolean(ctx.ctx);

      // Get auth from cookies in request context.
      const { user_id, accessToken } = cookies(ctx);
      const auth = { user_id, accessToken };

      /**
       * Pass apolloClient and auth in context so page `getInitialProps` can access both.
       * Always create new client on server; re-use client in browser.
       */
      if (typeof window === "undefined") {
        ctx.client = createApolloClient({ auth });
      } else {
        ctx.client = apolloClient || createApolloClient({ auth });
      }

      ctx.auth = auth;

      if (inAppContext) {
        ctx.ctx.client = ctx.client;
      }

      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      } else if (inAppContext) {
        pageProps = await App.getInitialProps(ctx);
      }

      if (typeof window === "undefined") {
        const { AppTree, res } = ctx;

        // When redirecting on server, response is finished and no rendering is required.
        if (res && (res.headersSent || res.finished)) {
          return pageProps;
        }

        // Recurse down `appTree` to run `useQuery` on server-side.
        if (AppTree) {
          try {
            let props;
            if (inAppContext) {
              props = { ...pageProps, auth: ctx.auth, client: ctx.client };
            } else {
              props = { pageProps: { ...pageProps, client: ctx.client }, auth };
            }
            await getDataFromTree(<AppTree {...props} />);
          } catch (error) {
            console.error("Error while running `getDataFromTree`", error);
          }
        }
      }

      /**
       * Ensure that server apolloClient is not sent to the browser as a prop,
       * but can still be used as a valid prop when passed down on server.
       */
      ctx.client.toJSON = () => null;

      return {
        client: ctx.client,
        apollo: ctx.client.extract(),
        ...pageProps,
      };
    };
  }

  return GraphQLProvider;
}
