import Head from "next/head";
import Link from "next/link";
import Router from "next/router";
import { useRouter } from "next/router";
import { Navbar, NavbarList } from "~/components/navbar";
import threadIcon from "~/assets/thread.svg";
import SignIn from "~/components/auth/signIn";
import { withApollo } from "~/providers/apollo";
import redirect from "./redirect";
function SignInPage({ redirect, client }) {
  const router = useRouter();
  const route = router.query.redirect
    ? "/" + (router.query.redirect as string).replace(/\s/g, "/")
    : redirect;
  // Reset Apollo client after authentication state is set on sign in.
  const onSignIn = () => client.resetStore().then(() => router.replace(route));
  return (
    <>
      <Head>
        <title>Login to Threads</title>
      </Head>
      <div className="min-h-screen w-full flex flex-col items-center">
        <Navbar className="max-w-7xl py-6 px-4 md:px-6">
          <NavbarList>
            <Link
              href="/"
              className="flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
              title="threads"
            >
              <img className="h-6 w-auto inline" src={threadIcon} alt="home" />
              <span className="font-bold text-black text-base tracking-tight">
                threads
              </span>
            </Link>
          </NavbarList>
        </Navbar>
        <SignIn className="mt-10 md:mt-20" onSignIn={onSignIn} />
      </div>
    </>
  );
}

SignInPage.getInitialProps = async (ctx) => {
  const signedIn = Boolean(ctx.auth.user_id);
  if (signedIn)
    return redirect(
      ctx,
      ctx.query.redirect
        ? "/" + (ctx.query.redirect as string).replace(/\s/g, "/")
        : "/",
    );
  return {
    redirect: ctx.res
      ? "/"
      : Router.asPath !== "/landing"
        ? Router.asPath
        : "/",
  };
};

export default withApollo(SignInPage);
