import { useState, useEffect } from "react";
import Link from "next/link";
import { useAuth } from "~/providers/auth";
import { authEndpoint } from "~/constants";
import * as Label from "@radix-ui/react-label";
import clsx from "clsx";

type SignInProps = {
  onSignIn: Function;
  className?: string;
  title?: string;
  onReset?: Function;
  onSignUp?: Function;
};

export function SignIn({
  onSignIn,
  className = "",
  title = "Sign in.",
  onReset = null,
  onSignUp = null,
}: SignInProps) {
  const [auth, setAuth] = useAuth();

  useEffect(
    function signInIfAuth() {
      auth.user_id && onSignIn();
    },
    [auth],
  );

  const [error, setError] = useState(null);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const body = JSON.stringify({
      email: form.email.value.toLowerCase(),
      password: form.password.value,
    });
    try {
      let resp = await fetch(`${authEndpoint}/signin`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body,
      });
      if (resp.ok) {
        const authResp = await resp.json();
        setAuth({ ...authResp });
        setError(null);
      } else {
        resp.text().then((error) => setError(error));
        setAuth({});
      }
    } catch (error) {
      setError("Unable to connect to the server.");
      setAuth({});
    }
  };

  return (
    <form
      className={clsx("w-96 max-w-full p-6 m-auto", className)}
      onSubmit={onSubmit}
    >
      <h2 className="font-bold text-2xl mb-4">{title}</h2>
      {error && (
        <div className="my-3 p-4 rounded font-medium text-sm text-red-500 bg-red-400 bg-opacity-10 ring-2 ring-red-400 ring-inset">
          {error}
        </div>
      )}
      <div className="my-2">
        <Label.Root
          className="block pt-1.5 pb-0.5 select-none uppercase font-semibold text-xs text-gray-400"
          htmlFor="email"
        >
          Email Address
        </Label.Root>
        <input
          className="my-1 px-3 h-10 w-full bg-gray-100 transition duration-[25ms] rounded ring-gray-200 border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-sky-300 focus:border-sky-300 hover:bg-gray-200 hover:bg-opacity-75 focus:hover:bg-gray-100"
          type="email"
          id="email"
          name="email"
          autoComplete="username"
          autoFocus
          required
        />
      </div>
      <div className="my-2">
        <Label.Root
          className="block pt-1.5 pb-0.5 select-none uppercase font-semibold text-xs text-gray-400"
          htmlFor="password"
        >
          Password
        </Label.Root>
        <input
          className="my-1 px-3 h-10 w-full bg-gray-100 transition duration-[25ms] rounded ring-gray-200 border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-sky-300 focus:border-sky-300 hover:bg-gray-200 hover:bg-opacity-75 focus:hover:bg-gray-100"
          type="password"
          id="password"
          name="password"
          autoComplete="current-password"
          required
        />
      </div>
      <div className="mb-2 mt-4">
        <button
          className="px-3 h-10 rounded font-medium text-xs my-1 transition duration-[25ms] leading-3 w-full text-sky-400 bg-sky-400 bg-opacity-10 focus:bg-opacity-20 hover:bg-opacity-20 active:bg-opacity-30 border-2 border-sky-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-sky-300 select-none"
          type="submit"
          aria-label="submit"
        >
          Continue
        </button>
      </div>
      <div className="my-2">
        <div className="text-xs leading-snug text-gray-400">
          <Link
            href="/reset"
            className="text-black visited:text-black underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            onClick={(e) => onReset && onReset(e)}
          >
            Forgot your password?
          </Link>
        </div>
        <div className="text-xs leading-snug text-gray-400">
          Need an account?{" "}
          <Link
            href="/signup"
            className="text-black visited:text-black underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            onClick={(e) => onSignUp && onSignUp(e)}
          >
            Register.
          </Link>
        </div>
      </div>
    </form>
  );
}

export default SignIn;
